import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { trialLicenseUrl } from '../constants/serviceUrls';
import { HeaderService } from './header.service';

@Injectable({
  providedIn: 'root'
})
export class TrialLicenseService {

  constructor(
    private http: HttpClient,
    private headerService: HeaderService
  ) { }
  getTrialLicense() {
    return this.http.get(trialLicenseUrl.licenseUrl, this.headerService.getHeadersWithoutCredentials())
  }

  public readLicense(): Observable<Blob> {
    return this.http.get(trialLicenseUrl.licensePath, { responseType: 'blob' });
  }

}
